var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-3" }, [
    _c("h2", [_vm._v("Nouvelle action à insérer")]),
    _vm._v(" "),
    _c("div", { staticClass: "container m-2 w-100" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.item.name,
            expression: "item.name",
          },
        ],
        staticClass: "border",
        attrs: { type: "test", placeholder: "add item" },
        domProps: { value: _vm.item.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.item, "name", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          class: [_vm.item.name ? "active" : "notactive"],
          on: {
            click: function ($event) {
              return _vm.addItem()
            },
          },
        },
        [_vm._v("\n            add +\n        ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }