var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container w-100 m-auto text-center mt-3" },
    [
      _c("h1", { staticClass: "text-danger" }, [_vm._v("action")]),
      _vm._v(" "),
      _c("add-item-form", {
        on: {
          reloadlist: function ($event) {
            return _vm.getItems()
          },
        },
      }),
      _vm._v(" "),
      _c("list-view", {
        staticClass: "text-center",
        attrs: { items: _vm.items },
        on: {
          reloadlist: function ($event) {
            return _vm.getItems()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }