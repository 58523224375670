var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "list-group-item d-flex justify-content-between w-50" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.item.completed,
            expression: "item.completed",
          },
        ],
        staticClass: "mr-3",
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.item.completed)
            ? _vm._i(_vm.item.completed, null) > -1
            : _vm.item.completed,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.item.completed,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.item, "completed", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.item,
                      "completed",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.item, "completed", $$c)
              }
            },
            function ($event) {
              return _vm.updateCheck()
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("span", { class: [_vm.item.completed ? "completed" : "", "item"] }, [
        _vm._v(_vm._s(_vm.item.name)),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn-danger ml-3",
          on: {
            click: function ($event) {
              return _vm.removeItem()
            },
          },
        },
        [_vm._v("X")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }