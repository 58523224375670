<template>
    <div class="mt-4">
        <h4>list task</h4>
        <hr class="w-50 m-auto mb-3" />
        <div class="card shadow-none mt-2"
            :item="item"
            v-on:itemchanged="$emit('reloadlist')"
            v-for="item in items"
            :key="item.id"
            >
            <div class="card-body p-2">
                <div class="task_title text-primary">
                    {{ item.libelle }}
                </div>
                <div class="task_date">
                    <i class="fa fa-clock"></i>
                </div>
                <div class="row mt-1  justify-content-between">
                    <div class="col-6">
                        <select name="status" id="changeStatus" class="form-control">
                            
                        </select>
                    </div>
                    <div class="col-6 text-right">
                        <i class="btn btn-sm btn-light fa fa-eye"></i>

                        <i class="btn btn-sm btn-light fa fa-edit
                            editModal" style="cursor: pointer" title="Modifier la action"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
        <ul class="list-group m-auto">
            <list-item
                :item="item"
                v-on:itemchanged="$emit('reloadlist')"
                v-for="item in items"
                :key="item.id"
                class="m-auto my-1 text-justify text-wrap"
            />
        </ul>
    </div>
</template>

<script>
import listItem from "./listItem";

export default {
    components: {
        listItem
    },
    props: ["items"]
};
</script>

<style scoped></style>
